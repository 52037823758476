import { withTranslation } from "react-i18next";
import { checkForArabic } from "../../helpers/arabicLangValidator";
import { getTagsIndex } from "../../helpers/urls";
import settings, {checkAreaMatchesForDescription} from "../../settings";
import FAQ from "./FAQ";
import { useParams } from 'react-router-dom'

const ShortDescription = ({ tags, city, t, renderBottomtagLinks, }) => {
	const { paramValue, city:cityParam } = useParams()

	function showShortDes() {
		if (!paramValue) return cityParam;
		const matchedDescription = checkAreaMatchesForDescription(paramValue, cityParam);
		return paramValue === matchedDescription ? matchedDescription : false;
	}
	  

	const renderHtmlContent = (key) => {
		return <p className="rental-guide-detail" dangerouslySetInnerHTML={{ __html: t(`${showShortDes()}${key}`) }}></p>;
	};

	return (
		<div className={`description-list ${checkForArabic("arabic-description-list")}`}>
			<div className="rental-guide">
				{showShortDes() && (
					<>
						<h2>{t(`${showShortDes()}rentalguide`)} </h2>
						{renderHtmlContent("rentaldesc")}
						{((paramValue && paramValue === cityParam) || !paramValue) && (
							<>
								<h2>{t(`cityguide`)} </h2>
								{renderHtmlContent("CityGuide")}
							</>
						)}
					</>
				)}
				<h2>{t(`cityarea`)}</h2>
				{renderBottomtagLinks(settings[getTagsIndex(city.urlName)])}

				{showShortDes() && (
					<>
						<h2>{t(`faqs`)}</h2>
						<FAQ city={city?.urlName} tags={tags} t={t} />
					</>
				)}
			</div>
		</div>
	);
};

export default withTranslation("short_desc")(ShortDescription);
