import { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumbs.scss";
import _ from "lodash";
import i18n from "../../i18n";
import {isPureString} from "../../helpers/urls";

const Breadcrumbs = ({ t, paramObj }) => {

  const {title, city , tags} = paramObj;
  const { pathname, search } = useLocation();
  const [pathSegments, setPathSegments] = useState([{ label: "home", url: "/" }]);
  const isTags = isPureString(pathname.split('/')[2]);

  useEffect(() => {
    const updateSegments = (segments) => {
      setPathSegments((prevPathSegments) => [...prevPathSegments.slice(0, 1), ...segments]);
    };

    let segments = [{ label: city, url: `/${city}` }];
    // book page
    if (pathname?.startsWith("/book")) {
      const propertyId = pathname.split("book/")[1];
      const array = [
        ...segments,
        { label: title, url: `/${city}/${propertyId}`, isTitle: true },
        { label: "book", url: pathname },
      ];
      return updateSegments(array);
    }

    // other pages
    if (!search && !pathname.startsWith(`/${city}/`) && pathname.split('/').length === 2) {
     return updateSegments([
        { label: pathname.split("/")[1], url: `${pathname}`, isTitle: true },
      ]);
    }

    // detail page - search page
    if (tags) {

      // isSearchPage = if length is less than 3 (only tags)
      const isSearchPage = pathname.split('/')?.length 
      segments.push({ label: tags, url: `/${city}/${tags}`, isTags: true });
      if (tags && isSearchPage > 3){
        segments.push({ label: title, url: `${pathname}`, isTitle: true });
      }
    }
    if (pathname.startsWith(`/${city}/`) && !tags) {
      segments.push({ label: title, url: `${pathname}`, isTitle: true });
    }
    updateSegments(segments);

  }, [pathname,search,title, tags, city, isTags]);

  const truncateLabel = (label) => _.truncate(_.upperFirst(label), { length: 15 });
  const checkIsTagLabel = (item) =>
    item?.isTags
      ? item.label
          ?.split(",")
          .map((tag) => t(`breadcrumb:${tag}`))
          .join(",")
      : t(`breadcrumb:${item.label}`);

  return (
    <div className="breadcrumbs">
      {pathSegments.map((item, index) => {
        const dir = i18n.language === 'ar' ? (item?.isTitle ? 'ltr' : 'rtl') : 'ltr';
        const isActive = index === pathSegments.length - 1;
        const label = truncateLabel(checkIsTagLabel(item).charAt(0)?.toUpperCase() + checkIsTagLabel(item)?.slice(1));

        return (
          <span key={index}>
            {isActive ? (
              <span title={item.label} className={` ${isActive ? "active" : ""}`} dir={dir}>
                {label}
              </span>
            ) : (
              <Link title={item.label} to={item.url} className={` ${isActive ? "active" : ""}`} dir={dir}>
                {label}
              </Link>
            )}
            {index < pathSegments.length - 1 && " > "}
          </span>
        );
      })}
    </div>
  );
};

export default memo(Breadcrumbs);
