import mapValues from "lodash/mapValues";

export const iconMap = {
  CM_detector: "CM_detector",
  air_condition: "air_condition",
  breakfast: "breakfast",
  buzzer: "buzzer",
  checkin: "checkin",
  doorman: "doorman",
  dryer: "dryer",
  elevator: "elevator",
  essentials: "essentials",
  events: "events",
  family_friendly: "family_friendly",
  fire_extinguisher: "fire_extinguisher",
  fireplace: "fireplace",
  first_aid: "first_aid",
  gym: "gym",
  hair_dryer: "hair_dryer",
  hangers: "hangers",
  heating: "heating",
  hot_tub: "hot_tub",
  iron: "iron",
  keypad: "keypad",
  kitchen: "kitchen",
  laptop: "laptop",
  lockbox: "lockbox",
  locker: "locker",
  parking: "parking",
  pets_allowed: "pets_allowed",
  pets_live: "pets_live",
  pool: "pool",
  private_entrance: "private_entrance",
  private_livingroom: "private_livingroom",
  safety_card: "safety_card",
  shampoo: "shampoo",
  smartlock: "smartlock",
  smoke_detector: "smoke_detector",
  smoking: "smoking",
  tv: "tv",
  washer: "washer",
  wheelchair: "wheelchair",
  wifi: "wifi",
  cats: "cats",
  dogs: "dogs",
  other_pets: "other_pets",
  internet: "internet",
  accessibility: "accessibility",
  baby: "baby",
  bath: "bath",
  bbq: "bbq",
  beach: "beach",
  bed: "bed",
  chair: "chair",
  cleaning: "cleaning",
  coffee: "coffee",
  console: "console",
  dishes: "dishes",
  dishwasher: "dishwasher",
  ev: "ev",
  garden: "garden",
  guard: "guard",
  hot_water: "hot_water",
  long_term: "long_term",
  luggage: "luggage",
  microwave: "microwave",
  oven: "oven",
  patio: "patio",
  refrigerator: "refrigerator",
  shades: "shades",
  stove: "stove",
  table: "table"
};

const requiredIconMap = mapValues(iconMap, name =>
  require(`../assets/icons/amenities/${name}.svg`)
);
export default requiredIconMap;