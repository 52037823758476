import { useEffect, useState } from "react";
import "./FAQ.scss";
import { withTranslation } from "react-i18next";
import settings, {checkAreaMatchesForDescription} from "../../settings";
import i18n from "../../i18n";

function FAQ({ city, t, tags }) {
	const [isOpen, setIsOpen] = useState(null);

	useEffect(() => {
		setIsOpen(null);
	}, [city]);

	const toggleAccordion = (index) => {
		setIsOpen(isOpen === index ? null : index);
	};

	const renderFAQItems = (faqs, prefix) => (
		Array(faqs).fill(undefined).map((que, index) => (
		  <dl className="listing-faq__item" key={index}>
			<dt className="listing-faq__question" onClick={() => toggleAccordion(index)}>
			  <span className="arrow">{isOpen === index ? '–' : '+'}</span>
			  <h3>{t(`${prefix}Title${index}`)}</h3>
			</dt>
			<dd
			  dangerouslySetInnerHTML={{ __html: t(`${prefix}Des${index}`) }}
			  className={`listing-faq__answer rental-guide-detail ${
				i18n.language === "ar" ? "arabic-listing-faq__answer" : ""
			  } ${isOpen === index ? "open" : ""}`}
			></dd>
		  </dl>
		))
	  );

	return (
		<div className="listing-faq__items">
			{
				renderFAQItems(settings[`${checkAreaMatchesForDescription(tags,city)}FAQs`],`${checkAreaMatchesForDescription(tags,city)}FAQs`)
			}
    	</div>
	);
}

export default withTranslation("short_desc")(FAQ);
