import React, { Component } from "react";
import settings, {getColumnCount} from "../../settings";
import "./Search.scss";
class Pagination extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalPages: this.countPages(),
			selectedPage: props.selectedPage ? parseInt(props.selectedPage) : 1,
		};
	}

	countPages() {
    let columnCount = getColumnCount();
		let defaultStarProperties = localStorage.getItem(settings.defaultStarProperties);
		let isDefaultSearch = localStorage.getItem(settings.isDefaulSearch);
    let pages = Math.ceil(this.props.itemsCount / settings.actualItemsPerPage);
		if (isDefaultSearch) {
			const firstPageItems = Math.min(this.props.itemsCount, defaultStarProperties);
			const remainingItems = this.props.itemsCount - firstPageItems;
			const remainingPages = Math.ceil(remainingItems / columnCount);
			pages =  1 + remainingPages;
		} 
    return pages
	}

  onPageClick(page) {
    this.setState({ selectedPage: page });
    this.props.onPageChange(page);
  }

  renderPages = () => {
    let table = [];
    for (let i = 0; i < this.state.totalPages; i++) {
      const page = i + 1;
      table.push(
        <li
          key={page}
          onClick={this.onPageClick.bind(this, page)}
          className={this.state.selectedPage === page ? "selected" : ""}
        >
          {page}
        </li>
      );
    }
    return table;
  };

  render() {
    return <ul className="pagination">{this.renderPages()}</ul>;
  }
}

export default Pagination;
