import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Pagination from "./Pagination";
import PropertyItem from "./PropertyItem";
import "./Search.scss";
import SearchMap from "./SearchMap";
import settings, {checkAreaMatchesForDescription} from "../../settings";
import { checkForArabic } from "../../helpers/arabicLangValidator";

class SearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
      hoveredProperty: null,
      visiblePropertiesIds: [],
    };

    this.propertiesVisibleDebounce = _.debounce((markers) => {
      this.setState({ visiblePropertiesIds: markers.map((x) => x.id) });
    }, 100);
  }

  dropdownToggle() {
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  }

  onDropdownClick(sort) {
    this.props.onDropdownClick(sort);
    this.setState({
      showDropdown: false,
    });
  }

  hideSelector() {
    this.setState({
      showDropdown: false,
    });
  }

  searchByName() {
    this.props.searchByNameToggle();
  }

  propertyHover(property) {
    this.setState({ hoveredProperty: property ? property : null });
  }

  onPageChange(page) {
    this.props.onChangePage(page);
  }

  // visibleMarkersChanged = (markers) => {
  //   this.propertiesVisibleDebounce(markers);
  // };

  showShortDes(){
    const {city: { urlName } } = this.props.state;
    const { paramValue } = this.props.match.params;
    if(!paramValue) return urlName
    const matchedDescription = checkAreaMatchesForDescription(paramValue, urlName);
    return paramValue === matchedDescription ? matchedDescription : false
  }

  render() {
    return (
      <div className={`search-result-container ${checkForArabic("arabic-search-result-container")}`}>
        <div className="properties-list-col">
          {this.props.downloadingItems ? (
            <div className="loader" />
          ) : (
            <div> 
              <div className={`properties-list ${checkForArabic("arabic-properties-list")}`}>
                <div className="short-intro-head">
                  {this.showShortDes() && <h1 className="short-intro-title">{this.props.t(`short_desc:${this.showShortDes()}Title`)}</h1>}
                  <div className="results-header">
                      <div className="properties-sorting">
                        <div className="dropdown-container">
                          <input
                            type="text"
                            onClick={this.dropdownToggle.bind(this)}
                            value={this.props.t(
                              this.props.state.sortMethod?.translationKey
                            )}
                            readOnly
                            placeholder={this.props.t("sortBy")}
                            className="dropdown-input dropdown-height"
                          />
                          <div
                            onClick={this.dropdownToggle.bind(this)}
                            className={
                              "dropdown-triangle dropdown-triangle-height" +
                              (this.state.showDropdown ? " rotate" : "")
                            }
                          ></div>
                          {this.state.showDropdown ? (
                            <div
                              className="overlay"
                              onClick={this.hideSelector.bind(this)}
                            ></div>
                          ) : null}
                          <ul
                            className={
                              "dropdown" + (this.state.showDropdown ? " show" : "")
                            }
                          >
                            {settings.sortMethods.map((sort) => (
                              <li
                                onClick={this.onDropdownClick.bind(this, sort)}
                                key={sort.key}
                              >
                                {this.props.t(sort.translationKey)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                  </div>
                </div>
                {this.showShortDes() && <p className="short-intro" dangerouslySetInnerHTML={{ __html: this.props.t(`short_desc:${this.showShortDes()}Description`)}} />}
                {this.props.properties?.map((property) => (
                  <PropertyItem
                    key={property._id}
                    property={property}
                    onPropertyHover={this.propertyHover.bind(this)}
                    {...this.props}
                  />
                ))}

                {/* <div className="total-properties">
                  {this.props.t(
                    this.props.totalProperties === 1
                      ? "propertyFound"
                      : "propertiesFound",
                    {
                      quantity: this.props.totalProperties,
                    }
                  )}
                </div> */}
                <Pagination
                  key={this.props.totalProperties}
                  onPageChange={this.onPageChange.bind(this)}
                  itemsCount={this.props.totalProperties}
                  selectedPage={this.props.selectedPage}
                  getListingParams={this.props.getListingParams}
                />
              </div>
            </div>
          )}
        </div>
        <div className={`search-map-col ${checkForArabic('arabic-map-col')}`} >
          {this.props.downloadingMapItems ? (
            <div className="loader" />
          ) : (
            <SearchMap
              history={this.props.history}
              properties={this.props.properties}
              mapProperties={this.props.mapProperties}
              hoveredProperty={this.state.hoveredProperty}
              getListingParams={this.props.getListingParams}
              {...this.props}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation("search")(SearchResult);
